.App {
  text-align: center;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.body {
  height: 100%;
  width: 100%;
}

.body > * {
  height: 100%;
}
