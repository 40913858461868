.LAC {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: auto;
  text-align: start;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.buttons {
  margin: 12px;
}

.at {
  display: none;
}

.continue {
  display: none;
}

.in-circle .continue {
  display: initial;
  margin: 0 16px;
}

.in-circle .at {
  display: initial;
}
